import Api from "@/api/apiAxios";
import Md5 from 'js-md5';
import { JSEncrypt } from 'jsencrypt'
import store from "@/store";
import {GET_LANGUAGE, GET_LOGIN_TOKEN} from "@/store/constants";

function encryption(data: any) {
	let _ = +new Date();
	let encrypt = new JSEncrypt();
	encrypt.setPublicKey("MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDnyo1CsYjdcL1Qrpt9Nm2NAvn5WgJOUFCevQyHf6z8zFMUpqrul3ZOsDaIxw9nfv52rToVsEK2JgRGlLSmCIuPpZwMjkjERNXNnZPqFM4dwLMQIb+P9wf/xX3kb5LtPj7gYHzXeFQI94qvOS+lPwgQbA+zmDx7tswGOLASKTRr3QIDAQAB");
	return Object.assign(data, {
		_ : _,
		cypherKey: encrypt.encrypt(data.cypherKey)
	});
}

export default {
    webLogin: (userName:string,password:string) => Api.postRequest('/auth/login', encryption({
		loginType: 'BACK_WEB',
		username: userName,
		cypherKey: password
	})),
	login: (data:{}) => Api.postRequest('/auth/login', encryption(data)),
	logout: () => Api.postRequest('/auth/login', {loginType: 'LOGOUT_CURRENT'}),
    tokenLogin: () => Api.postRequest('/auth/login', {
        loginType: 'TOKEN'
    }),
    listMenuTree: () => Api.getRequest('/menu/listMenuTree'),
	listMenuTreeByUser: () => Api.getRequest('/menu/listMenuTreeByUser'),
	queryDictByParentId: (parentId:string) => Api.getRequest(`/dict/queryByParentId/${parentId}`)
		.then(response => {
			if(store.getters[GET_LANGUAGE] !== 'zh'){
				// @ts-ignore
				response && response.data && response.data.map(item => item.name = item.value);
			}
			return response;
		}),
	downloadUrl: (fileName:string) => `${process.env.VUE_APP_BASE_API}/common/download?fileName=${encodeURI(fileName)}&delete=true&LOGIN_TOKEN=${store.getters[GET_LOGIN_TOKEN]}`,
	aliyunOssSign: () => Api.getRequest(`/aliyun/sign`),
	websocketUrl: (userId:string) => `/websocket/imserver/${userId}`,
	getImageCode: (imageGuid:string) => `${process.env.VUE_APP_BASE_API}/common/getImageCode/${imageGuid}`,
	getAllDict: () => Api.getRequest(`/dict/getAllDict`),
	switchIdentities: (data:{}) => Api.postRequest(`/user/switchIdentities`, encryption(data)),
	listProvinceCity: () => Api.getRequest(`/city/listProvinceCity`),						//获取省市
	listProvinceCityArea:()=> Api.getRequest(`/city/listProvinceCityArea`),					//获取省市区
	ocr: (params:{}) => Api.postRequest('/common/ocr',params),
	bankList:(params:object)=> Api.postRequest(`/bank/list/1/100`,params),						//获取银行列表
	branchList:(params:object)=> Api.postRequest(`/branch/list/1/100`,params),						//获取支行
	listMcc:(params:object)=> Api.postRequest(`/api/common/listMcc`,params),						//获取MCC
	sendVerifyCodeSms:(params:object)=> Api.postRequest(`/api/common/sendVerifyCodeSms`,params),	//发送短信
	forgetPassword:(params:object)=> Api.postRequest(`/user/forgetPassword`,params),				//忘记密码
	verifySmsCode:(params:object)=> Api.postRequest(`/api/common/verifySmsCode`,params),			//校验短信
};


