
import {routerPath, routerPathA} from '../tool';
import Layout from '@/views/layout/Layout.vue'

export default {
    path: '/activityQuery',
    name: 'activityQuery',
    component: Layout,
    meta: {
        title: '活动查询',
        hasIndex: true,
		tabView: true
    },
    children: [
        routerPathA('activityQuery', 'terminalActivityQuery', '机具活动查询',false),
        routerPathA('activityQuery', 'merchantActivityQuery', '商户活动查询',false),
    ]
};