import './element';
import './icons';
import Pagination from "@/components/Pagination.vue";
import OssUpload from "@/components/OssUpload.vue";
import Vue from 'vue'
import { Message } from 'element-ui'
import store from "@/store";
import {GET_LOGIN_TOKEN} from "@/store/constants";
import Permission from '@/directive/permission';
import {translate, downloadFile, dictTranslate,filterObj,replaceComma} from "@/utils/tools";
import echarts from 'echarts';
import * as custom  from '@/filter'

Vue.prototype.Message = Message;
Vue.prototype.downloadFile = downloadFile;
Vue.prototype.dictTranslate = dictTranslate;
Vue.prototype.translate = translate;
Vue.prototype.filterObj = filterObj;
Vue.prototype.replaceComma = replaceComma;
store.dispatch("app/choiceElementUiLocale");
Vue.component('Pagination', Pagination);
Vue.component('OssUpload', OssUpload);
Vue.directive("permission", Permission);
Vue.prototype.$echarts = echarts;
Object.keys(custom).forEach(key => {
    Vue.filter(key, custom[key])
  })