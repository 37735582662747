import { routerPath, routerPathA } from "../tool";
import Layout from "@/views/layout/Layout.vue";

export default {
	path: "/terminalManage",
	name: "terminalManage",
	component: Layout,
	meta: {
		title: "机具管理",
		hasIndex: true,
		tabView: true
	},
	children: [
		routerPathA("terminalManage", "terminalQuery", "机具管理", false),
		routerPathA("terminalManage", "terminalBatchUpdate", "机具批量划拨"),
		routerPathA("terminalManage", "terminalDelete", "机具批量回收"),
		routerPathA("terminalManage", "terminalUpdate", "机具划拨"),
		routerPathA("terminalManage", "terminalSave", "机具入库"),
		routerPathA("terminalManage", "terminalRemove", "机具删除"),
		routerPathA("terminalManage", "terminalOperRecord", "机具操作日志")
	]
};
