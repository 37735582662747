const GLOBAL_STORE_STATE = 'global_store_State';
function localLoadDate(key:string) {
    try {
        let item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    } catch (e) {
        localStorage.removeItem(key);
        return null;
    }
}
function localSaveDate(key:string, data:any) {
    if (data) {
        localStorage.setItem(key, JSON.stringify(data));
    }
}
function sessionLoadDate(key:string) {
    try {
        let item = sessionStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    } catch (e) {
        localStorage.removeItem(key);
        return null;
    }
}
function sessionSaveDate(key:string, data:any) {
    if (data) {
        sessionStorage.setItem(key, JSON.stringify(data));
    }
}
export default {
    loadStoreState: function () {
        return localLoadDate(GLOBAL_STORE_STATE);
    },
    saveStoreState: function (state:any) {
        localSaveDate(GLOBAL_STORE_STATE, state);
    },
    clearData: function () {
        localStorage.clear();
        sessionStorage.clear();
    }
}