import {CommonApi,ProfitManage} from '@/api';
import {Store} from "vuex";
import router from '@/router';
import {deepAssign} from '@/utils/tools';
// @ts-ignore
import elementUiLocale from 'element-ui/lib/locale'

const SET_BREADCRUMB_LIST = `SET_BREADCRUMB_LIST`;
const SET_LOGIN_USER_INFO = `SET_LOGIN_USER_INFO`;
const IS_MOBILE = `IS_MOBILE`;
const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
const DO_NOT_SHOW_GUIDE = 'DO_NOT_SHOW_GUIDE';
const SET_LANGUAGE = 'SET_LANGUAGE';
const SET_LOCALE = 'SET_LOCALE';
const SET_COUNTRY = 'SET_COUNTRY';
const SET_DICTS = 'SET_DICTS';
const SET_PROVINCECITY = 'SET_PROVINCECITY';
const SET_PROVINCECITYAREA = 'SET_PROVINCECITYAREA';

const state = {
    breadcrumbs: [],
    userInfo: {},
	authCodes:[],
    loginToken: '',
    isLogin: false,
    loginErrorMessage: '',
	isMobile: false,
	hasTagsView: true,
	sidebar: {
		opened: true,
		withoutAnimation: false
	},
	showGuide: true,
	language: 'zh',
	country: 'CN',
	locale: 'zh-CN',
	dicts: {},
	provinceCity: [],
	provinceCityArea: [],
};
const getters = {
    getLoginToken: (state: any) => state.loginToken,
	getAuthCodes: (state:any) => state.authCodes,
	getUserInfo: (state:any) => state.userInfo,
	getLanguage: (state:any) => state.language,
	getCountry: (state:any) => state.country,
	getDicts: (state:any) => state.dicts,
	getProvinceCity: (state:any) => state.provinceCity,
	getProvinceCityArea: (state:any) => state.provinceCityArea
};
const mutations = {
	[SET_LANGUAGE]: (state:any, language:string) => {
		state.language = language || 'zh';
	},
	[SET_COUNTRY]: (state:any, country:string) => {
		state.country = country || '';
	},
	[SET_LOCALE]: (state:any, locale:string) => {
		state.locale = locale || 'zh-CN';
	},
	[DO_NOT_SHOW_GUIDE]: (state:any) => {
		state.showGuide = false;
	},
    [SET_BREADCRUMB_LIST]: (state:any, breadcrumbs:any[]) => {
        state.breadcrumbs = breadcrumbs;
    },
    [SET_LOGIN_USER_INFO]: (state:any, user:any) => {
        state.isLogin = (user && user.success) || false;
        state.userInfo = (user && user.data) || {};
        state.loginToken = (user && user.data && user.data.loginToken) || '';
        state.loginErrorMessage = (user && user.message) || '';
		state.authCodes = (user && user.data && user.data.authCodes) || [];
    },
	[IS_MOBILE]: (state:any, isMobile: boolean) => {
		state.isMobile = isMobile;
	},
	[TOGGLE_SIDEBAR]: (state:any) => {
		state.sidebar.opened = !state.sidebar.opened;
	},
	[CLOSE_SIDEBAR]: (state: any, withoutAnimation: boolean) => {
		state.sidebar.opened = false;
		state.sidebar.withoutAnimation = withoutAnimation;
	},
	[SET_DICTS]: (state: any, dicts: object) => {
		state.dicts = deepAssign({}, state.dicts, dicts || {});
		console.log("dicts --> %o", state.dicts);
	},
	[SET_PROVINCECITY]: (state: any, provinceCity: object) => {
		state.provinceCity =  provinceCity
	},
	[SET_PROVINCECITYAREA]: (state: any, provinceCityArea: object) => {
		state.provinceCityArea =  provinceCityArea
	}
};
let actions = {
    setBreadcrumb: ({commit}:Store<any>, breadcrumbs:any[]) => {
        commit(SET_BREADCRUMB_LIST, breadcrumbs);
    },
	userLogin: async ({commit, dispatch}: Store<any>, user: any) => {
		let userInfo = await CommonApi.login({
			loginType: user.loginType,
			username: user.username,
			phone: user.username,
			cypherKey: user.password,
			imageCode: user.imageCode,
			imageGuid: user.imageGuid,
			smsCode: user.smsCode
		});
		commit(SET_LOGIN_USER_INFO, userInfo);
		if(userInfo.success){
			dispatch('getAllDict');
			dispatch('getAllProvinceCityArea');
		}
		
	},
	logout: ({commit,dispatch}: Store<any>) => {
		CommonApi.logout();
		dispatch('tagsView/delAllViews',[], {root: true});
		commit(SET_LOGIN_USER_INFO, {});
		router.push("/login");
	},
    tokenLogin: async ({commit}: Store<any>) => {
        let userInfo = await CommonApi.tokenLogin();
        commit(SET_LOGIN_USER_INFO, userInfo);
    },
	setDeviceMode: ({commit}: Store<any>, isMobile: boolean) => {
		commit(IS_MOBILE, isMobile);
	},
	closeSideBar({ commit }: Store<any>, withoutAnimation: boolean) {
		commit(CLOSE_SIDEBAR, withoutAnimation)
	},
	toggleSideBar({ commit }: Store<any>) {
		commit(TOGGLE_SIDEBAR)
	},
	doNotShowGuide({ commit }: Store<any>) {
		commit(DO_NOT_SHOW_GUIDE);
	},
	choiceLanguage({commit,dispatch}: Store<any>, locale: string) {
		commit(SET_LANGUAGE, locale.split("-")[0]);
		commit(SET_COUNTRY, locale.split("-")[1]);
		commit(SET_LOCALE, locale);
		dispatch('choiceElementUiLocale');
		dispatch('getAllDict');
	},
	choiceElementUiLocale({state}:Store<any>) {
		let local = state['locale'];
		let s = require.context('element-ui/lib/locale/lang/', false, /js/);
		let hasTheLocal = s.keys().filter(it => it === `./${local}.js`).length > 0
		import(`element-ui/lib/locale/lang/${hasTheLocal ? local : 'zh-CN'}`)
			.then(lang => {
				elementUiLocale.use(lang.default)
			});
	},
	getAllDict:async({commit, state}: Store<any>) => {
		let data = await CommonApi.getAllDict();
		let result = await ProfitManage.acqChannel.listAllChannel();
		if(data.data){
			data.data.CHANNEL = {}
			for(let i of result.data){
				data.data.CHANNEL[i.channelNo] =i.name
			}
		}
		commit(SET_DICTS, data.data);
	},
	changeUserRole: async ({commit, dispatch,}: Store<any>, user: any) => {
		let userInfo = await CommonApi.switchIdentities({
			userRole: user.userRole,
		});
		dispatch('tagsView/delAllViews', [], {root: true})
		commit(SET_LOGIN_USER_INFO, userInfo);
		dispatch('getAllDict');
	},
	getAllProvinceCity:async({commit, state}: Store<any>) => {
		let data = await CommonApi.listProvinceCity();
		commit(SET_PROVINCECITY, data.data);
	},
	getAllProvinceCityArea:async({commit, state}: Store<any>) => {
		let data = await CommonApi.listProvinceCityArea();
		commit(SET_PROVINCECITYAREA, data.data);
	},
};

export default {
    state,
    getters,
    mutations,
    actions
}
