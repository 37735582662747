import Api from "@/api/apiAxios";
export default {
	category: {
		...Api.generalAPI('category')
	},
	attribute: {
		...Api.generalAPI('attribute')
	},
	specification: {
		...Api.generalAPI('specification')
	},
	brand: {
		...Api.generalAPI('brand')
	},
	product: {
		...Api.generalAPI('product')
	},
	productAttribute: {
		...Api.generalAPI('productAttribute')
	},
	productSpecification: {
		...Api.generalAPI('productSpecification')
	},
	goodsSku: {
		...Api.generalAPI('goodsSku')
	},
	goodsGallery: {
		...Api.generalAPI('goodsGallery')
	},
};
