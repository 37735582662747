<template>
	<div id="tags-view-container" class="tags-view-container">
		<!-- {{visitedViews}} -->
		<ScrollPane class="tags-view-wrapper" ref="scrollPane">
			<RouterLink class="tags-view-item" v-for="view in visitedViews"
						ref="view"
						tag="span"
						:key="view.path"
						:class="isActive(view)?'active':''"
						:to="{ path: view.path, query: view.query, fullPath: view.fullPath }"
						@click.middle.native="!isAffix(view) && closeSelectedTag(view)"
						@contextmenu.prevent.native="openMenu(view,$event)"
						>
				<span class="tags-item-txt">{{translate(view.meta.title)}}</span>
				<span class="el-icon-close" v-if="!isAffix(view)" @click.prevent.stop="closeSelectedTag(view)"></span>
			</RouterLink>
		</ScrollPane>
		<ul class="contextmenu" v-show="visible"  :style="{left:left+'px',top:top+'px'}">
			<li @click="refreshSelectedTag(selectedTag)">{{translate('COMMON.REFRESH')}}</li>
			<li v-if="!isAffix(selectedTag)" @click="closeSelectedTag(selectedTag)">{{translate('COMMON.CLOSE')}}</li>
			<li @click="closeOthersTags">{{translate('COMMON.CLOSE_OTHER')}}</li>
			<li @click="closeAllTags(selectedTag)">{{translate('COMMON.CLOSE_ALL')}}</li>
		</ul>
	</div>
</template>

<script>
	import ScrollPane from './ScrollPane'
	import {mapState, mapActions} from 'vuex';

	export default {
		components: { ScrollPane },
		data() {
			return {
				visible: false,
				top: 0,
				left: 0,
				selectedTag: {},
				affixTags: []
			}
		},
		watch: {
			visible(value) {
				if (value) {
					document.body.addEventListener('click', this.closeMenu)
				} else {
					document.body.removeEventListener('click', this.closeMenu)
				}
			}
		},
		computed: {
			...mapState('tagsView', ['visitedViews'])
		},
		methods: {
			...mapActions('tagsView', ['delView', 'delCachedView', 'delOthersViews', 'delAllViews', 'updateVisitedView']),
			isAffix(tag) {
				return tag.meta && tag.meta.affix
			},
			isActive(route) {
				return route.path === this.$route.path
			},
			closeSelectedTag(view) {
				let indexOf = this.visitedViews.indexOf(view);
				this.delView(view).then(({ visitedViews }) => {
					if (this.isActive(view)) {
						let nextView = (indexOf >= visitedViews.length) ? visitedViews[visitedViews.length - 1] : visitedViews[indexOf];
						this.toView(nextView, view)
					}
				});
			},
			toView(newView, oldView){
				if (newView) {
					this.$router.push(newView.fullPath)
				} else {
					// now the default is to redirect to the home page if there is no tags-view,
					// you can adjust it according to your needs.
					if (oldView.name === 'Dashboard') {
						// to reload home page
						this.$router.replace({ path: '/redirect' + oldView.fullPath })
					} else {
						this.$router.push('/')
					}
				}
			},
			toLastView(visitedViews, view) {
				const latestView = visitedViews.slice(-1)[0];
				this.toView(latestView, view);
			},
			openMenu(tag, e) {
				const menuMinWidth = 105;
				const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
				const offsetWidth = this.$el.offsetWidth; // container width
				const maxLeft = offsetWidth - menuMinWidth; // left boundary
				const left = e.clientX - offsetLeft + 15; // 15: margin right
				if (left > maxLeft) {
					this.left = maxLeft
				} else {
					this.left = left
				}
				this.top = e.clientY - 50;
				this.visible = true;
				this.selectedTag = tag;
			},
			closeMenu(){
				this.visible = false;
			},
			refreshSelectedTag(view) {
				this.delCachedView(view).then(() => {
					const { fullPath } = view
					this.$nextTick(() => {
						this.$router.replace({
							path: '/redirect' + fullPath
						})
					})
				})
			},
			closeOthersTags() {
				this.$router.push(this.selectedTag);
				this.delOthersViews(this.selectedTag).then(() => {
					this.moveToCurrentTag()
				})
			},
			closeAllTags(view) {
				this.delAllViews().then(({ visitedViews }) => {
					if (this.affixTags.some(tag => tag.path === view.path)) {
						return
					}
					this.toLastView(visitedViews, view)
				})
			},
			moveToCurrentTag() {
				const tags = this.$refs.tag;
				this.$nextTick(() => {
					if(!!tags){
						for (const tag of tags) {
							if (tag.to.path === this.$route.path) {
								this.$refs.scrollPane.moveToTarget(tag)
								// when query is different then update
								if (tag.to.fullPath !== this.$route.fullPath) {
									// this.updateVisitedView(this.$route)
								}
								break
							}
						}
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../../style/variables.scss";
	.tags-view-container {
		position: absolute;
		top: #{headerNavbarHeigh};
		left: 0;
		height: 32px;
		width: 100%;
		.tags-view-wrapper {
			.tags-view-item {
				background-color: #fff;
				border-color: #d9ecff;
				display: inline-block;
				height: 26px;
				padding: 0 10px;
				line-height: 26px;
				font-size: 12px;
				color: #323232;
				border-width: 1px;
				border-style: solid;
				border-radius: 4px;
				box-sizing: border-box;
				white-space: nowrap;
				position: relative;
				cursor: pointer;
				margin-left: 5px;
				margin-top: 6px;
				&:first-of-type {
					margin-left: 15px;
				}
				&:last-of-type {
					margin-right: 15px;
				}
				&.active {
					background-color: #409eff;
					color: #fff;
					border-color: #409eff;
					&::before {
						content: '';
						background: #fff;
						display: inline-block;
						width: 8px;
						height: 8px;
						border-radius: 50%;
						position: relative;
						margin-right: 2px;
					}
				}
				.tags-item-txt{
					margin-right: 5px;
				}
			}
		}
		.contextmenu {
			margin: 0;
			background: #fff;
			z-index: 4000;
			position: absolute;
			list-style-type: none;
			padding: 5px 0;
			border-radius: 4px;
			font-size: 12px;
			font-weight: 400;
			color: #333;
			box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);
			li {
				margin: 0;
				padding: 7px 16px;
				cursor: pointer;
				&:hover {
					background: #eee;
				}
			}
		}
	}
	//reset element css of el-icon-close
	.tags-view-wrapper {
		.tags-view-item {
			.el-icon-close {
				width: 16px;
				height: 16px;
				vertical-align: 3px;
				border-radius: 50%;
				text-align: center;
				transition: all .3s cubic-bezier(.645, .045, .355, 1);
				transform-origin: 100% 50%;
				&:before {
					display: inline-block;
					vertical-align: -3px;
				}
				&:hover {
					background-color: #409eff;
					color: #fff;
				}
			}
		}
	}
</style>

