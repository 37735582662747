import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout/Layout.vue'
import Login from '../views/Login.vue'
import modules from './modules';
import store from '@/store';
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { translate } from '@/utils/tools';
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'layout',
        component: Layout,
        children: [{
            path: '/home',
            name: 'home',
			meta: {
				affix: true,
				title: 'MENU_TREE.HOME',
				tabView: true
			},
            component: () => import('../views/layout/Home.vue')
        },
        {
            path: '/updatePassword',
            name: 'updatePassword',
            meta:{
                tabView: true,
                title:'修改密码'
            },
            component: () => import(/* webpackChunkName: "about" */ '../views/userAuthority/updatePassword/Index.vue')
        }]
    },
    {
        path: '/login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Login
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/forgetPassword',
        name: 'forgetPassword',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/forgetPassword.vue')
    }
];
console.log("module ->> %o", modules)
routes.push(...modules);
const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
});

export default router



NProgress.configure({ showSpinner: false })
router.beforeEach((to, from, next) => {
	NProgress.start();
	let breadcrumbs = to.matched.filter(item => item.meta && !!item.meta.title)
		.map(item => {
			return{
				title: translate(item.meta.title),
				path: !!item.meta.hasIndex ? `${item.path}/index`: item.path,
				hasIndex: !!item.meta.hasIndex
			}
		});
	store.dispatch('app/setBreadcrumb', breadcrumbs);
	if (to.meta && to.meta.tabView) {
		store.dispatch('tagsView/addView', {
			path: to.path,
			query: to.query,
			fullPath: to.fullPath,
			name: to.name,
			meta: to.meta
		});
	}
	next(); // 确保一定要调用 next()
	NProgress.done();
});

router.afterEach(() => {
	// finish progress bar
	NProgress.done()
});
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location:any) {
  return originalPush.call(this, location).catch((err:any) => err)
}
