<template>
	<el-breadcrumb class="app-breadcrumb"  separator-class="el-icon-arrow-right">
		<el-breadcrumb-item v-if="hasHome">{{translate('MENU_TREE.HOME')}}</el-breadcrumb-item>
		<template v-else>
			<el-breadcrumb-item :to="{ path: '/home' }">
				{{translate('MENU_TREE.HOME')}}
			</el-breadcrumb-item>
			<template v-for="item in breadcrumbs">
				<el-breadcrumb-item v-if="item.hasIndex" :to="{path: item.path}">
					{{translate(item.title)}}
				</el-breadcrumb-item>
				<el-breadcrumb-item v-else>
					{{translate(item.title)}}
				</el-breadcrumb-item>
			</template>
		</template>
	</el-breadcrumb>
</template>

<script>
	import {mapState} from 'vuex'

	export default {
		name: 'Breadcrumb',
		data() {
			return {
				levelList: null
			}
		},
		computed: {
			...mapState('app', ['breadcrumbs']),
			hasHome:function() {
				return this.breadcrumbs.filter(v => v.path === '/home').length > 0
			}
		}
	}
</script>

<style lang="scss" scoped>
	.app-breadcrumb.el-breadcrumb {
		.no-redirect {
			color: #97a8be;
			cursor: text;
		}
	}
</style>
