<template>
	<div>
		<section class="app-main" :class="classObj">
			<div class="main-bg">
				<transition name="fade-transform" mode="out-in">
					<keep-alive :include="cachedViews">
						<router-view :key="key"></router-view>
					</keep-alive>
				</transition>
			</div>
			
	</section>
	</div>
	
</template>

<script>
	import {mapState} from 'vuex';
	import Breadcrumb from '@/components/Breadcrumb';
	export default {
		name: 'AppMain',
		components:{Breadcrumb},
		computed: {
			...mapState('tagsView', ['visitedViews', 'cachedViews']),
			...mapState('app', ['sidebar', 'hasTagsView', 'isMobile','userInfo']),
			classObj() {
				if(this.userInfo.org){
					document.querySelector('body').style = '--color:rgb(8,16,58)'
					document.documentElement.setAttribute('class',"isOrg")
				}
				return {
					sideBarHide: !this.sidebar.opened,
					hasTagsView: this.hasTagsView,
					mobile: this.isMobile
				}
			},
			key() {
				return this.$route.path
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../style/variables.scss";
	.app-main {
		/* 50= navbar  50  */
		min-height: calc(100vh - 50px);
		width: 100%;
		position: relative;
		margin: 0;
		overflow: hidden;
		padding: 15px;
		top: 24px;
		&.hasTagsView {
			min-height: calc(100vh - 140px);
		}
		&.fixed-header + .app-main {
			padding-top: 84px;
		}
		&.mobile{
			position: relative;
			margin: 0;
			left: 0;
		}
	}

	.fixed-header+.app-main {
		padding-top: 50px;
	}
</style>

<style lang="scss">
	// fix css style bug in open el-dialog
	.el-popup-parent--hidden {
		.fixed-header {
			padding-right: 15px;
		}
	}
</style>
