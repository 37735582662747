<template>
	<div class="wrap">
		<header>
			<!-- <span></span> -->
		</header>
		<div class="logGet">
			<div class="box1">
				<div class="logD logDtip">
					<p class="p1">代理商登录</p>
				</div>
				<el-tabs v-model="loginType" @tab-click="handleClick" stretch>
					<el-tab-pane label="账号登录" name="BACK_WEB">
						<div class="lgD">
							<img
								src="../assets/phone.png"
								width="20"
								height="20"
							/>
							<input
								ref="usernameInput"
								type="text"
								v-model="username"
								maxlength="11"
								placeholder="请输入登录用户"
								@keyup="keyUp($event)"
							/>
						</div>
						<div class="lgD">
							<img
								src="../assets/password.png"
								width="20"
								height="20"
							/>
							<input
								ref="passwordInput"
								type="password"
								v-model="password"
								placeholder="请输入密码"
								@keyup="keyUp($event)"
							/>
						</div>
						<div class="lgD">
							<img
								src="../assets/code.png"
								width="20"
								height="20"
							/>
							<input
								ref="imageCodeInput"
								maxlength="4"
								type="text"
								v-model="imageCode"
								placeholder="请输入验证码"
								@keyup="keyUp($event)"
							/>
							<img
								class="imageCode"
								:src="imgCodeSrc"
								width="80"
								height="20"
								@click="refreshImageCode"
							/>
						</div>
						<div class="forget-Password">
							<a href="#/forgetPassword">忘记密码</a>
						</div>
						<div class="logC">
							<el-button
								type="primary"
								ref="loginBtn"
								@click="login"
								@keyup="keyUp($event)"
								>登录</el-button
							>
						</div>
					</el-tab-pane>
					<el-tab-pane label="短信登录" name="MOBILE_PHONE_CODE">
						<div class="lgD">
							<img
								src="../assets/phone.png"
								width="20"
								height="20"
							/>
							<input
								ref="usernameInput"
								type="text"
								v-model="username"
								maxlength="11"
								placeholder="请输入手机号码"
								@keyup="keyUp($event)"
							/>
						</div>
						<div class="lgD">
							<img
								src="../assets/code.png"
								width="20"
								height="20"
							/>
							<input
								ref="imageCodeInput"
								maxlength="4"
								type="text"
								v-model="imageCode"
								placeholder="请输入验证码"
								@keyup="keyUp($event)"
							/>
							<img
								class="imageCode"
								:src="imgCodeSrc"
								width="80"
								height="20"
								@click="refreshImageCode"
							/>
						</div>
						<div class="lgD">
							<img
								src="../assets/message.png"
								width="20"
								height="20"
							/>
							<input
								ref="imageCodeInput"
								maxlength="4"
								type="text"
								v-model="smsCode"
								placeholder="请输入短信验证码"
								@keyup="keyUp($event)"
							/>
							<el-button
								class="getCode"
								@click="getCode"
								size="medium"
								:disabled="disabled"
								type="primary"
								>{{ getTxt }}</el-button
							>
						</div>
						<div class="logC">
							<el-button
								type="primary"
								ref="loginBtn"
								@click="smsLogin"
								@keyup="keyUp($event)"
								>登录</el-button
							>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
			<div class="box2">
				<!-- <div class="qrcode"><img src="../assets/qrcode.png"><p>乐刷付APP下载</p></div> -->
			</div>
		</div>
		<div class="login-bottom">豫ICP备2022026181号-1</div>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { guid } from "@/utils/tools";
import { CommonApi } from "@/api";
export default {
	name: "Login",
	data: function() {
		return {
			username: "",
			password: "",
			smsCode: "",
			imageCode: "",
			imageGuid: "",
			changeBox: false,
			checkRole: "",
			loginType: "BACK_WEB",
			getTxt: "获取短信验证码",
			time: 60,
			disabled: false,
			resetBtn: false
		};
	},
	mounted() {
		this.refreshImageCode();
	},
	computed: {
		...mapState("app", {
			userInfo: "userInfo",
			isLogin: "isLogin"
		}),
		imgCodeSrc: function() {
			return CommonApi.getImageCode(this.imageGuid);
		}
	},
	methods: {
		handleClick() {},
		...mapActions("app", ["userLogin", "changeUserRole"]),
		refreshImageCode: function() {
			this.imageGuid = guid();
			console.log("imageGuid = " + this.imageGuid);
		},
		login: async function() {
			if (!this.username || !this.password) {
				this.$message.warning(
					this.translate("FROM.PLACEHOLDER", {
						param: "LOGIN.LOGIN_NAME_OR_PASSWORD"
					})
				);
				return;
			}
			if (!this.imageCode) {
				this.$message.warning(
					this.translate("FROM.PLACEHOLDER", {
						param: "LOGIN.IMAGE_VERIFICATION_CODE"
					})
				);
				return;
			}
			let result = await this.userLogin({
				loginType: this.loginType,
				username: this.username,
				phone: this.username,
				password: this.password,
				imageCode: this.imageCode,
				imageGuid: this.imageGuid,
				smsCode: this.smsCode
			});
			if (this.isLogin) {
				this.$router.push("/home");
			} else {
				this.password = "";
				this.imageCode = "";
				this.refreshImageCode();
			}
		},
		smsLogin: async function() {
			if (!this.username) {
				this.$message.warning("请输入手机号码");
				return;
			}
			if (!this.imageCode) {
				this.$message.warning("请输入图形验证码");
				return;
			}
			if (!this.smsCode) {
				this.$message.warning("请输入短信验证码");
				return;
			}
			await this.userLogin({
				loginType: this.loginType,
				username: this.username,
				phone: this.username,
				password: this.password,
				imageCode: this.imageCode,
				imageGuid: this.imageGuid,
				smsCode: this.smsCode
			});
			if (this.isLogin) {
				this.$router.push("/home");
			} else {
				this.password = "";
				this.imageCode = "";
				this.refreshImageCode();
			}
		},
		keyUp: function(event) {
			if (event.key !== "Enter") {
				return;
			}
			if (event.target === this.$refs.usernameInput) {
				if (this.username === "") {
					this.$message.warning(
						this.translate("FROM.PLACEHOLDER", {
							param: "LOGIN.LOGIN_NAME"
						})
					);
					return;
				}
				this.$refs.passwordInput.focus();
			} else if (event.target === this.$refs.passwordInput) {
				if (this.password === "") {
					this.$message.warning(
						this.translate("FROM.PLACEHOLDER", {
							param: "LOGIN.PASSWORD"
						})
					);
					return;
				}
				this.$refs.imageCodeInput.focus();
			} else if (event.target === this.$refs.imageCodeInput) {
				if (this.imageCode === "") {
					this.$message.warning(
						this.translate("FROM.PLACEHOLDER", {
							param: "LOGIN.IMAGE_VERIFICATION_CODE"
						})
					);
					return;
				}
				this.$refs.loginBtn.focus();
			} else {
				this.login();
			}
		},
		async getCode() {
			if (!this.username) {
				this.$message.warning("请输入手机号码");
				return;
			}
			if (!this.imageCode) {
				this.$message.warning("请输入图形验证码");
				return;
			}
			this.timer();
			let result = await CommonApi.sendVerifyCodeSms({
				smsType: "PHONE_SMS_CODE_LOGIN",
				phone: this.username,
				imageCode: this.imageCode,
				imageCodeId: this.imageGuid
			});
			if (result.success) {
				this.$message.success("发送成功");
				this.resetBtn = false;
			} else {
				this.$message.error(result.message);
				this.imageCode = "";
				this.resetBtn = true;
			}
		},
		timer() {
			if (this.resetBtn) {
				clearTimeout(this.timer);
				this.time = 60;
				this.getTxt = "获取短信验证码";
				this.disabled = false;
			} else {
				if (this.time > 0) {
					this.time--;
					this.getTxt = this.time + "S后重新获取";
					this.disabled = true;
					setTimeout(this.timer, 1000);
				} else {
					this.time = 60;
					this.getTxt = "获取短信验证码";
					this.disabled = false;
				}
			}
		}
	}
};
</script>

<style lang="scss" scoped>
* {
	margin: 0;
	padding: 0;
}
body {
	margin: 0;
	padding: 0;
}
.head {
	height: 120px;
	width: 100%;
	background-color: #66cccc;
	text-align: center;
	position: relative;
}
.wrap {
	background: #f7f7f7;
	overflow: hidden;
}
.wrap .logGet {
	width: 750px;
	height: 540px;
	background: #fff;
	box-shadow: 0 30px 50px 0 rgba(124,172,255,0.12);
	border-radius: 10px;
	font-size: 0;
	margin: 0 auto;
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	color: #1a263b;
}

.logC button {
	width: 100%;
	height: 45px;
	border: none;
	color: white;
	font-size: 18px;
}

.logGet .logD.logDtip .p1 {
	display: inline-block;
	font-size: 26px;
	color: #1a263b;
	width: 86%;
	font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
		"Microsoft YaHei", "微软雅黑", Arial, sans-serif;
	font-weight: bold;
}
.errorMsg {
	color: red;
}

.wrap .logGet .logD.logDtip {
	text-align: center;
	padding: 20px;
}

.logGet .lgD img {
	position: absolute;
	top: 12px;
	left: 8px;
}
.logGet .lgD img.imageCode {
	position: absolute;
	top: 12px;
	left: 335px;
	cursor: pointer;
}

.logGet .lgD input {
	width: 100%;
	height: 42px;
	text-indent: 2.5rem;
}

.wrap .logGet .lgD {
	width: 86%;
	position: relative;
	margin-bottom: 30px;
	margin-top: 30px;
	margin-right: auto;
	margin-left: auto;
}

.wrap .logGet .logC {
	width: 86%;
	margin-top: 0px;
	margin-right: auto;
	margin-bottom: 0px;
	margin-left: auto;
}

.title {
	color: #ffffff;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	/* 使用css3的transform来实现 */
	font-size: 36px;
	height: 40px;
	width: 30%;
}

.copyright {
	color: #ffffff;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	/* 使用css3的transform来实现 */
	height: 60px;
	width: 40%;
	text-align: center;
}
.getCode {
	position: absolute;
	top: 3px;
	right: 3px;
	cursor: pointer;
}
.forget-Password {
	width: 86%;
	margin: -20px auto 15px;
	text-align: right;
}
.box1 {
	width: 500px;
	float: left;
}
.box2 {
	width: 200px;
	float: left;
	border-left: 1px solid #eee;
	p {
		text-align: center;
		padding: 10px 0;
		margin: 0;
		font-size: 14px;
	}
}
.qrcode {
	width: 250px;
	padding: 20px;
	height: 488px;
	img {
		margin-top: 140px;
		width: 100%;
		display: block;
	}
}
header {
	background: #fff;
	span {
		width: 300px;
		height: 80px;
		display: block;
		background: url(../assets/logo_new.png) no-repeat 20px center;
	}
}
.logGet input {
	border: 1px solid #e8eaec;
	border-radius: 4px;
	height: 50px;
}
</style>
