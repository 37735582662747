/**
 * @author kco1989
 * @email kco1989@qq.com
 * @date 2019-11-30 14:01
 */
let routerPath = (parent:string, menu:string, title?:string) => {
    let firstUpMenu = menu.substr(0, 1).toUpperCase() + menu.substr(1);
    return {
        path: `/${parent}/${menu}`,
        name: firstUpMenu === 'Index' ? `${parent}Index` : menu,
        meta: {
            title: title,
			tabView: true
        },
        component: () => import(`@/views/${parent}/${firstUpMenu}.vue`)
        // component: (resolve:any) => require([`@/views/${parent}/${firstUpMenu}.vue`], resolve)
    }
};
let routerPathA = (parent:string, menu:string, title?:string, isNoCache = true) => {
	let firstUpMenu = menu.substr(0, 1).toUpperCase() + menu.substr(1);
	return {
		path: `/${parent}/${menu}`,
		name: firstUpMenu === 'Index' ? `${parent}Index` : menu,
		meta: {
			title: title,
			tabView: true,
			noCache: isNoCache
		},
		component: () => import(`@/views/${parent}/${menu}/Index.vue`),
		// component: (resolve:any) => require([`@/views/${parent}/${firstUpMenu}.vue`], resolve)
	}
};
export {
    routerPath,
	routerPathA
};
