/**
 * @author kco1989
 * @email kco1989@qq.com
 * @date 2019-12-06 08:48
 */
const files = require.context('.', false, /\.ts$/);
const isTestDebug = process.env.VUE_APP_TEST_DEBUG === 'true';
const modules:any[] = [];
files.keys().forEach((key) => {
    if (key === './index.ts') return;
    if (key === './test.ts' && !isTestDebug) return;
    const vuexObj = files(key);
    modules.push(vuexObj.default);
});
export default modules;