import {routerPath, routerPathA} from '../tool';
import Layout from '@/views/layout/Layout.vue'

export default {
    path: '/tradeManage',
    name: 'tradeManage',
    component: Layout,
    meta: {
        title: '交易管理',
        hasIndex: true,
		tabView: true
    },
    children: [
        routerPathA('tradeManage', 'tradeQuery', '交易查询',false),
    ]
};
