/**
 * @author kco1989
 * @email kco1989@qq.com
 * @date 2019-11-30 13:59
 */
import {routerPath, routerPathA} from '../tool';
import Layout from '@/views/layout/Layout.vue'

export default {
    path: '/devManage',
    name: 'devManage',
    component: Layout,
    meta: {
        title: 'MENU_TREE.DEV_MANAGE',
        hasIndex: true,
		tabView: true
    },
    children: [
        routerPath('devManage', 'index', 'MENU_TREE.DEV_MANAGE'),
        routerPathA('devManage', 'devDocManage', 'MENU_TREE.DEV_DOC_MANAGE'),
        routerPathA('devManage', 'swaggerDocManage', 'MENU_TREE.SWAGGER_DOC_MANAGE')
    ]
};
