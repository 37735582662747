<template>
	<div id="side-bar">
		<div class="side-logo"></div>
		<div class="side-title">{{isOrg?'机构系统':'代理商系统'}}</div>
		<el-scrollbar wrap-class="scrollbar-wrapper">
			<el-menu
				:unique-opened="true"
				:background-color="variables.menuBg"
				:text-color="variables.menuText"
				:active-text-color="variables.menuActiveText"
				@select="clickMenu"
				:collapse-transition="false" mode="vertical">
				<el-submenu v-for="submenu in menuList" :index="submenu.menuId" :key="submenu.menuId">
					<template slot="title">
						<i :class="submenu.icon"></i>
						<!-- <span>{{showI18nName(submenu)}}</span> -->
						<span>{{submenu.name}}</span>
					</template>
					<template  v-if="submenu.children && submenu.children.length > 0">
						<el-menu-item v-for="menuItem in submenu.children"
								  :index="menuItem.menuId" :key="menuItem.menuId">
						<!-- <i :class="menuItem.icon"></i> -->
						<!-- {{showI18nName(menuItem)}} -->
						{{menuItem.name}}
					</el-menu-item>
					</template>
				</el-submenu>
			</el-menu>
		</el-scrollbar>
	</div>
</template>

<script>
	import { CommonApi } from "@/api";
	import testMenu from "@/test/testMenu"
	import variables from '@/style/variables.scss'
	import {mapState, mapActions} from 'vuex';
	import Logo from './Logo'
    export default {
        name: "SideBar",
		components:{
			Logo
		},
		data: function () {
			return {
				menuList: [],
				subMenu: {}
			}
		},
		computed: {
			variables() {
				return variables
			},
			...mapState('app',['sidebar', 'isMobile','userInfo']),
			isOrg(){
                return this.userInfo.org
            },
		},
		mounted() {
			this.listMenuTree();
		},
		methods: {
			...mapActions('app', [ 'closeSideBar']),
			showI18nName(menu){
				let menuId = menu.menuId.replace(/([A-Z])/g, "_$1").toLocaleUpperCase();
				let menuKey = `MENU_TREE.${menuId}`;
				let menuName = this.translate(`MENU_TREE.${menuId}`);
				return menuName === menuKey ? menu.name : menuName;
			},
			listMenuTree: async function(){
				this.menuList  = (await CommonApi.listMenuTreeByUser()).data || [];
				// if (process.env.VUE_APP_TEST_DEBUG === 'true') {
				// 	this.menuList.push(testMenu);
				// }
				this.menuList.forEach((firstMenu,firstIndex) => {
					(firstMenu.children || []).forEach((secondMenu,secondIndex) => {
						if(this.userInfo.currentIdentity.userRole=='AGENT' && secondMenu.url=='/rateChangeApply'){
							this.menuList[firstIndex].children.splice(secondIndex,1)
						}
						this.subMenu[secondMenu.menuId] = {
							menuNameList: [firstMenu.name, secondMenu.name],
							menuId: secondMenu.menuId,
							url: /^http/i.test(secondMenu.url) ? secondMenu.url : firstMenu.url + secondMenu.url,
							target: secondMenu.target,
							name: secondMenu.name
						}
					})
				})
			},
			clickMenu: function (menuId) {
				console.log("clickMenu --> %o", this.subMenu[menuId]);
				let subMenu = this.subMenu[menuId];
				if (/^http/i.test(subMenu.url)) {
					let a = document.createElement("a");
					a.href = subMenu.url;
					a.target = "_black";
					a.click();
				}else {
					this.$router.push(subMenu.url);
				}
				if (this.isMobile) {
					this.closeSideBar();
				}
			}
		}
    }
</script>

<style scoped>
	#side-bar .side-title,
	#side-bar i{
		color: rgb(216, 222, 230);
	}
	#side-bar .is-active i{
		color: #fff;
	}
</style>
