import store from '../store'
import { GET_DICTS } from '@/store/constants';
let provinceCity = store.getters['app/getProvinceCityArea']
/**
 * 过滤卡类型
 *
 */
const formateCardType = function (val: any) {
    const list = {
        '0': '-',
        '1': '贷记卡',
        '2': '借记卡'
    }
    return list[val]
}
/**
 * 结算状态
 * @param val
 * @returns
 */
const setterStatusFM = function (val: any) {
    const list = {
        '1': '正常',
        '2': '冻结',
        '3': '注销',
        '4': '分润冻结'
    }
    return list[val]
}
/**
 * 开关
 * @param val
 * @returns
 */
const switchFM = function (val: any) {
    const list = {
        '0': '关闭',
        '1': '开启',
    }
    return list[val]

}
/**
 * 用户状态
 * @param val
 * @returns
 */
const userStatusFM = function (val: any) {
    const list = {
        '0': '已创建',
        '1': '审核失败',
        '2': '待审核',
        '3': '审核终止',
        '4': '正常',
        '5': '注销',
    }
    return list[val]

}
/**
 * 支付类型
 * @param val
 * @returns
 */
const payType = function (val: any) {
    let dicts = store.getters[GET_DICTS]
    let PAY_TYPE = dicts.PAY_TYPE
    return PAY_TYPE[val]
}

/**
 * 商户状态
 * @param val
 * @returns
 */
const changeCheckStatusFM = function (val: any) {
    const list = {
        '0': '审核中',
        '1': '审核成功',
        '2': '审核失败',
    }
    return list[val]
}

/**
 * 进件来源
 * @param val
 * @returns
 */
const registerSourceFM = function (val: any) {
    const list = {
        '1': 'APP',
        '2': '代理商WEB',
    }
    return list[val]
}
/**
 *  过滤省市
 * @param val
 * @returns
 */
const provinceCityFM = function (val: any, cityCode: any) {
    let children = []
    if (cityCode) {
        for (let i = 0; i < provinceCity.length; i++) {
            if (provinceCity[i].code == cityCode) {
                children = provinceCity[i].children
            }
        }
        for (let i = 0; i < children.length; i++) {
            if (children[i].code == val) {
                return children[i].name
            }
        }
    }
    for (let i = 0; i < provinceCity.length; i++) {
        if (provinceCity[i].code == val) {
            return provinceCity[i].name
        }
    }
}
/**
 *
 * @param val
 * @returns
 */
const companyTypeFM = function (val: any) {
    const list = {
        '1': '移动',
        '2': '固定'
    }
    return list[val]
}
/**
 * 账户类型
 * @param val
 * @returns
 */
const accountTypeFM = function (val: any) {
    const list = {
        '2': '对公',
        '1': '对私'
    }
    return list[val]
}

/**
 *  时间区间
 * @param val
 * @returns
 */
const formatRangDate = function (val: any) {
    if (!val) {
        return
    }
    if (val != '长期') {
        if (Array.isArray(val)) {
            return val.join('至')
        } else {
            return val.replace(',', '至')
        }

    } else {
        return val
    }
}

/**
 * 机具状态类型
 * @param val
 * @returns
 */
const terminalStatusFM = function (val: any) {
    const list = {
        '1': '已入库',
        '2': '已绑定',
        '3': '已下发'
    }
    return list[val]
}
/**
 * 	结算状态
 * @param val
 * @returns
 */
const settleStatusFM = function (val: any) {
    const list = {
        '0': '初始化',
        '1': '结算中',
        '2': '结算失败',
        '3': '结算成功',
        '4': '冻结中',
        '5': '冻结成功',
        '6': '止付中',
        '7': '止付成功',
        '8': '退票',
        '9': '未知',
        '10': '转账成功'
    }
    return list[val]
}
/**
 * 	交易状态
 * @param val
 * @returns
 */
const transStatusFM = function (val: any) {
    const list = {
        'SUCCESS': '成功',
        'FAILED': '失败',
        'REVOKED': '撤销',
        'REVERSED': '已冲正',
    }
    return list[val]
}
/**
 * 	账户类型
 * @param val
 * @returns
 */
const balanceTypeFM = function (val: any) {
    const list = {
        'PROFIT': '分润账户',
        'RECASH': '返现',
    }
    return list[val]
}
/**
 * 	提现状态
 * @param val
 * @returns
 */
const extractionStatusFM = function (val: any) {
    const list = {
        'INIT': '初始化',
        'SUCCESS': '成功',
        'FAILED': '失败',
    }
    return list[val]
}
/**
 * 	出款状态
 * @param val
 * @returns
 */
const transferStatusFM = function (val: any) {
    const list = {
        'INIT': '初始化未出款',
        'SUCCESS': '成功',
        'FAILED': '失败',
        'REVERSED': '已冲正',
    }
    return list[val]
}
/**
 * 	打款状态
 * @param val
 * @returns
 */
const payStatusFM = function (val: any) {
    const list = {
        'INIT': '初始化',
        'UNCOMMIT': '提交失败',
        'WAITING': '出款中',
        'SUCCESS': '出款成功',
        'FAILED': '出款失败',
        'UNKNOW': '未知',
    }
    return list[val]
}
/**
 * 	止付类型
 * @param val
 * @returns
 */
const suspendTypeFM = function (val: any) {
    const list = {
        '1': '机具款项',
        '2': '风险止付',
        '3': '活动止付',
    }
    return list[val]
}
/**
 * 	止付状态
 * @param val
 * @returns
 */
const stopPaymentStatusFM = function (val: any) {
    const list = {
        'INIT': '初始化',
        'WAITING': '止付中',
        'COMPLETE': '已完成',
        'REMOVE': '已解除',
    }
    return list[val]
}

/**
 * 身份
 * @param val
 * @returns
 */
const userRoleFM = function (val: any) {
    const list = {
        'ORG': '机构',
        'AGENT': '代理商',
    }
    return list[val]
}

/**
 * 变更类型
 * @param val
 */
const changeTypeFM = function (val: any) {
    const list = {
        'RATE': '费率变更',
        'STATUS': '状态变更',
        'SETTLE_CARD': '结算卡变更',
    }
    return list[val]
}

/**
 * 账户分润类型
 * @param val
 */
const accountProfitTypeFM = function (val: any) {
    const list = {
        'POS_PROFIT': '交易分润',
        'ACTIVE_PROFIT': '活动分润'
    }
    return list[val]
}
/**
 * 业务类型
 * @param val
 */
const accountChangeTypeFM = function (val: any) {
    const list = {
        'PROFIT_SHARE': '分润入账',
        'ACTIVITY_CASH_BACK': '活动返现',
        'PROFIT_WITHDRAWAL': '分润账户提现',
        'PROFIT_WITHDRAWAL_OFFSET': '分润账户提现冲正',
        'PROFIT_WITHDRAWAL_COMPLETED': '分润账户提现完成',
        'ACTIVITY_WITHDRAWAL': '活动账户提现',
        'ACTIVITY_WITHDRAWAL_OFFSET': '活动账户提现冲正',
        'ACTIVITY_WITHDRAWAL_COMPLETED': '活动账户提现完成',
        'SUSPEND_INIT': '止付订单初始化',
        'SUSPEND_WAITING': '止付订单止付中',
        'SUSPEND_COMPLETE': '止付订单已完成',
        'SUSPEND_REMOVE': '止付订单已解除'
    }
    return list[val]
}
/**
 * 操作类型
 * @param val
 */
const accountChangeStatusFM = function (val: any) {
    const list = {
        'INCOME': '流入',
        'OUTCOME': '流出',
    }
    return list[val]
}
/**
 * 商户账户类型
 * @param val
 */
const bankCardAccountTypeFM = function (val: any) {
    const list = {
        '1': '个人',
        '2': '企业',
    }
    return list[val]
}
/**
 * 乐刷商户状态
 * @param val
 * @returns
 */
const lsStateFM = function (val: any) {
    const list = {
        '-2': '商户不存在',
        '-1': '待完善资料',
        '0': '待审核',
        '1': '审核失败',
        '2': '人工审核',
        '3': '审核成功',
    }
    return list[val]
}
/**
 * 机具活动类型
 * @param val
 * @returns
 */
const activityTypeFM = function (val: any) {
    const list = {
        'expire_reduce': '逾期未激活扣款',
        'flow_card': '流量卡',
    }
    return list[val]
}
/**
 * 操作类型
 * @param val
 * @returns
 */
const operationTypeFM = function (val: any) {
    const list = {
        'REWARD': '奖励',
        'DEDUCTION': '扣款',
    }
    return list[val]
}
/**
 * 达标状态
 * @param val
 * @returns
 */
const targetStatusFM = function (val: any) {
    const list = {
        'init': '考核中',
        'success': '已达标',
        'fail': '未达标',
    }
    return list[val]
}
/**
 * 入账状态
 * @param val
 * @returns
 */
const accountStatusFM = function (val: any) {
    const list = {
        'init': '未入账',
        'success': '已入账',
        'fail': '未入账',
    }
    return list[val]
}

/**
 * 商户活动类型
 * @param val
 * @returns
 */
const merActivityTypeFM = function (val: any) {
    const list = {
        'active': '商户激活奖励',
        'repeat_active': '商户重复激活奖励',
        'fake': '商户伪激活扣款',
        'invalid': '失效激活奖励',
        'standard1': '一次达标奖励',
        'standard2': '二次达标奖励',
        'expire_reduce': '逾期未激活扣款',
        'flow_card': '流量卡',
    }
    return list[val]
}

/**
 * 	打款状态
 * @param val
 * @returns
 */
const transferFM = function (val: any) {
    const list = {
        'INIT': '初始化',
        'SUCCESS': '出款成功',
        'FAILED': '出款失败',
        'UNCOMMIT': '提交失败',
        'WAITING': '出款中',
        'UNKNOW': '未知',
    }
    return list[val]
}
/**
 * 	审核商户管理状态
 * @param val
 * @returns
 */
const auditStatusFM = function (val: any) {
    const list = {
        '1': '待审核',
        '2': '审核失败',
        '3': '正常',
        '4': '注销',
    }
    return list[val]
}
/**
 * 	审核账号类型
 * @param val
 * @returns
 */
const auditAccountTypeFM = function (val: any) {
    const list = {
        '1': '对公结算',
        '2': '对私结算',
        '3': '非法人对私结算',
    }
    return list[val]
}
/**
 * 	结算卡变更账号类型
 * @param val
 * @returns
 */
const settleAccountTypeFM = function (val: any) {
    const list = {
        '1': '对公',
        '2': '对私',
        '3': '非法人对私',
    }
    return list[val]
}
/**
 * 	新商户状态
 * @param val
 * @returns
 */
const operTypeFM = function (val: any) {
    const list = {
        "ISSUE": "机具下发",
        "RECOVER": "机具回收",
        "WAREHOUSING": "机具入库"
    }
    return list[val]
}
/**
 * 	新商户状态
 * @param val
 * @returns
 */
const newMerchantStatusFM = function (val: any) {
    const list = {
        'UN_COMPLETE': '待完善',
        'UN_CHECK': '待审核',
        'CHECK_FAIL': '审核失败',
        'CHECK_SUCC': '正常',
        'UN_KNOW': '未知',
    }
    return list[val]
}
const newAccountTypeFM = function (val: any) {
    const list = {
        '3': '非法人对私结算',
        '2': '对私结算',
        '1': '对公结算'
    }
    return list[val]
}
/**
 * 机具类型
 * @param val
 * @returns
 */
const hardwareTypeFM = function (val: any) {
    const list = {
        '1': '电签',
        '2': '传统',
        '3': '二维码',
    }
    return list[val]
}
/**
 * 渠道
 * @param val
 * @returns
 */
const channelFM = function (val: any) {
    let dicts = store.getters[GET_DICTS]
    let CHANNEL = dicts.CHANNEL
    return CHANNEL[val]
}
/**
 * 
 * @param val 调价类型
 * @returns 
 */
const adjustTypeFM = function (val: any) {
    const adjustTypes = {
        "A": "调价A",
        "C": "调价C",
        "B": "调价B"
    };
    return adjustTypes[val];
}
const adjustStrategyFM=function(val:any){
    const adjustStrategies={
        "MERCHANT_REGISTER_STANDARD":"从商户入网开始满"
    };
    return adjustStrategies[val];
}
const adjustAuditStatusFM = function(val:any){
    const adjustAuditStatuses={
        "WAITING":"审核中",
        "SUCCESS":"审核成功",
        "FAILED":"审核失败"
    };
    return adjustAuditStatuses[val];
}
export {
    formateCardType,
    setterStatusFM,
    switchFM,
    userStatusFM,
    payType,
    registerSourceFM,
    changeCheckStatusFM,
    provinceCityFM,
    companyTypeFM,
    accountTypeFM,
    formatRangDate,
    terminalStatusFM,
    settleStatusFM,
    transStatusFM,
    balanceTypeFM,
    extractionStatusFM,
    transferStatusFM,
    payStatusFM,
    stopPaymentStatusFM,
    suspendTypeFM,
    userRoleFM,
    changeTypeFM,
    accountProfitTypeFM,
    accountChangeTypeFM,
    accountChangeStatusFM,
    bankCardAccountTypeFM,
    lsStateFM,
    activityTypeFM,
    operationTypeFM,
    targetStatusFM,
    accountStatusFM,
    merActivityTypeFM,
    transferFM,
    auditStatusFM,
    auditAccountTypeFM,
    settleAccountTypeFM,
    newMerchantStatusFM,
    newAccountTypeFM,
    channelFM,
    hardwareTypeFM,
    operTypeFM,
    adjustTypeFM,
    adjustStrategyFM,
    adjustAuditStatusFM
};
